











import { Component, Vue, Prop } from 'vue-property-decorator'
import SelectBase from '@/components/atoms/SelectBase.vue'

@Component({
  components: {
    SelectBase,
  },
})
export default class SelectPeriod extends Vue {
  @Prop({ default: false })
  isTeacher?: boolean

  @Prop({ default: '1week' })
  value!: string

  @Prop({ default: null })
  periodOptions?: { text: string; value: string }[]

  private selectPeriodOptions = this.periodOptions
    ? [...this.periodOptions]
    : [
        { text: '1週間', value: '1week' },
        { text: '1ヶ月', value: '1month' },
        { text: '3ヶ月', value: '3month' },
        { text: '6ヶ月', value: '6month' },
        { text: '12ヶ月', value: '12month' },
      ]

  get propSet() {
    if (this.isTeacher) return null
    return {
      border: '0px',
      btnColor: 'var(--color-blue-1)',
      bgColor: 'var(--color-gray-4)',
    }
  }

  private changeSelect(event: Event): void {
    this.$emit('input', event)
  }
}
