













import { Component, Prop, Mixins } from 'vue-property-decorator'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'

@Component
export default class SelectSubject extends Mixins(StyleSubjectBackground) {
  private selectOptions: { text: string; value: string }[] = [
    { text: '算数/数学', value: 'su' },
    { text: '英語', value: 'ei' },
    { text: '国語', value: 'ko' },
    { text: '理科', value: 'ri' },
    { text: '社会', value: 'sh' },
  ]

  @Prop({ default: null })
  onChangeFunction?: any

  @Prop()
  value?: string

  @Prop({ default: false })
  showAll?: boolean

  // 特定の教科のみプルダウンに表示したい場合に設定。表示する教科コードを配列で渡す
  // 例：['ko', 'su']
  @Prop({ default: null })
  onlyShow?: string[] | null

  get styleSet(): any {
    const _: any = this
    return function (code: string) {
      code = this.value
      return _.getSubjectStyle(code)
    }
  }

  get options(): object[] {
    if (!this.onlyShow) return this.selectOptions
    return this.selectOptions.filter((option: { text: string; value: string }) => {
      return this.onlyShow && this.onlyShow.includes(option.value)
    })
  }

  private changeSelect(event: Event): void {
    this.$emit('input', (event.target as HTMLSelectElement).value)
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }

  private clickOption(event: Event): void {
    if (this.onChangeFunction !== null) this.onChangeFunction(event)
  }
}
