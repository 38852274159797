import { Component, Vue } from 'vue-property-decorator'
import {
  GetSubjectResultSummary,
  GetSubjectResultCurriculums,
  GradeCurriculums,
} from '@/models/api/history/subjectResult'

@Component
export default class SubjectSituationWebApi extends Vue {
  /**
   * チャート表示用データと、学習結果を取得する
   * @param { userId: number; term: string; subjectCode: string; classMode: string }
   */
  protected async loadChartRecordsApi(params: {
    userId: number
    term: string
    subjectCode: string
    classMode: string
  }) {
    const { data }: { data: GetSubjectResultSummary } = await Vue.prototype.$http.httpWithToken.get(
      `/history/subjectResult/summary`,
      {
        params: params,
      }
    )

    // チャート用のスコア配列をセット
    const chartDatas = data.scores

    // 学習結果セット
    const result = {
      questionCount: data.quesitonCounts,
      correctCount: data.correctCounts,
      hour: data.duration.hour,
      minute: data.duration.minute,
    }

    // 表示日付セット
    const dateSteps = data.dateSteps

    return { chartDatas, result, dateSteps }
  }

  /**
   * 項目毎の理解度一覧を取得
   * @param { userId: number; subjectCode: string; classMode: string }
   */
  protected async loadGradeHistoriesApi(params: {
    userId: number
    subjectCode: string
    classMode: string
    term?: string
  }) {
    const { data }: { data: GetSubjectResultCurriculums } = await Vue.prototype.$http.httpWithToken.get(
      `/history/subjectResult/curriculums`,
      {
        params: params,
      }
    )

    // 項目毎の累計理解度セット
    const curriculums = data.curriculums.map((curriculumData) => {
      return this.parseGradeHistories(curriculumData)
    })

    return { curriculums: curriculums, termExamId: data.termExamId }
  }

  /**
   * APIで受け取った学年ごとの理解度一覧をパースする
   * @param GradeCurriculums
   */
  private parseGradeHistories(curriculumData: GradeCurriculums) {
    return {
      gradeName: curriculumData.gradeName,
      gradeCode: curriculumData.gradeCode,
      date: curriculumData.date,
      count: curriculumData.count,
      histories: curriculumData.curriculumSUnits.map((unit) => {
        const rates = [
          { rate: unit.maxPredictedScore, rateText: `${unit.minPredictedScore}-${unit.maxPredictedScore}` },
        ]
        if (unit.prevMinPredictedScore !== undefined && unit.prevMaxPredictedScore !== undefined) {
          rates.push({
            rate: unit.prevMaxPredictedScore,
            rateText: `${unit.prevMinPredictedScore}-${unit.prevMaxPredictedScore}`,
          })
        }
        return {
          sUnitId: unit.id,
          subject: { name: unit.subjectName, code: unit.subjectCode },
          content: unit.name,
          date: unit.date,
          count: unit.count,
          rates: rates,
        }
      }),
    }
  }
}
