import { Component, Vue } from 'vue-property-decorator'

export type Subject = { id: number; name: string; code: string; sortNum: string }

@Component
export default class LoadEnableSubjectsApi extends Vue {
  /**
   * GDLSサービスで有効な教科情報を取得する
   *
   * @param branchId 教室ID
   */
  protected async loadGdlsSubjectsApi(branchId: number, userId?: number): Promise<Subject[]> {
    const res: { data: Subject[] } = await Vue.prototype.$http.httpWithToken.get(`/branchServices/subjects`, {
      params: { branchId: branchId, serviceCode: 'gdls', userId: userId },
    })
    return res.data
  }

  //バージョン 3 を追加
  /**
   * GDLSサービスで有効な教科情報を取得する
   *
   * @param branchId 教室ID
   */
  protected async loadGdlsSubjectsApiV3(branchId: number, userId?: number, classModeCode?: string): Promise<Subject[]> {
    let serviceCode = 'gdls'
    if (classModeCode === 'NY') {
      serviceCode = 'nyushi'
    }
    const res: { data: Subject[] } = await Vue.prototype.$http.httpWithToken.get(`v3/branchServices/subjects`, {
      params: { branchId: branchId, serviceCode: serviceCode, userId: userId, classModeCode: classModeCode },
    })
    return res.data
  }
}
