




































import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import StudentFaceIcon from '@/components/atoms/StudentFaceIcon.vue'
import RatioBarText from '@/components/atoms/RatioBarText.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import { RouterHelper } from '@/router/routerHelper'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

export type History = {
  sUnitId?: number // 小項目ID
  subject: { name: string; code: string } // 教科情報(教科名の表示と、生徒画面でのスタイル変更に使用する)
  content: string // 項目名
  date?: string // 学習時間
  count?: number // 学習回数
  rates: { rate: number; rateText: string }[] // 理解度
  contenLink?: string // TODO 遷移先リンク
}

@Component({
  components: {
    StudentFaceIcon,
    RatioBarText,
    ButtonBase,
  },
})
export default class StudentHistoryContent extends Mixins(StyleSubjectBackground) {
  @Prop()
  histories!: History[]

  @Prop({ default: false })
  isSubjectAll?: boolean

  @Prop({ default: false })
  isTeacher?: boolean

  @Prop({ default: false })
  isShowAgainBtn!: boolean

  @Prop({ default: false })
  isTerm?: boolean

  @Prop({ default: '' })
  selectedSubject?: string

  // URLから授業種別を判別(normal/test/seminar/entrance)
  // 現在のURLが履歴系のURL(/history/normal/~)である事を想定している
  private get learningType() {
    if (this.isTeacher) {
      // 教師画面ではcookieにクラスモードを持っていない為、パスを参照
      const type = new RouterHelper(this.$route).persePathes().third
      return ['normal', 'test', 'seminar', 'entrance', 'custom', 'normal-v1'].includes(type) ? type : 'normal'
    } else {
      // 生徒画面ではcookieの情報が正である為、cookieを参照
      const classMode = Vue.prototype.$cookies.get('dataGdls').classMode
      const types = { TJ: 'normal', TK: 'test', KS: 'seminar', NY: 'entrance' }
      return types[classMode] || 'normal'
    }
  }

  // 学習履歴一覧へ遷移
  private studyUnitLink(curriculumSUnitId: number, modeCode = '') {
    const baseUrl = this.isTeacher
      ? `/teacher/history/${this.learningType}/study-unit`
      : `/student/history/${this.learningType}/study-unit`
    return `${baseUrl}/${curriculumSUnitId}?prevPage=${this.$route.name}&selectedSubject=${this.selectedSubject}&modeCode=${modeCode}`
  }

  get barStyleSet(): any {
    const _: any = this
    return function (code: string) {
      let fillColor = null
      let lineColor = null

      if (_.isTeacher) {
        fillColor = 'var(--color-blue)'
        lineColor = 'var(--color-blue-3)'
      } else {
        const subjectStyle = _.getSubjectStyle(code)
        fillColor = subjectStyle['--subject-bg-color']
        lineColor = subjectStyle['--subject-linear-gradiant']
      }
      return {
        '--bar-fill-color': fillColor,
        '--bar-line-color': lineColor,
      }
    }
  }

  // もう一度ボタン押下 生徒画面のみ表示
  private async onClickAgainBtn(subjectCode: string) {
    // TODO: 通常モードに切り替えて小項目単位の学種画面へ遷移
    // TODO: 暫定で教科毎の項目一覧に飛ばしてます
    // こちら、通常学習と講習会で遷移先を変更しました
    // 参照: https://combine.backlog.jp/view/GDLS-1210
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie.subjectCode = subjectCode
    await Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())

    let path = ''

    switch (this.learningType) {
      case 'normal':
        path = '/student/curriculum-s-units'
        break
      case 'seminar':
        path = '/student/seminar-unit-selection'
        break
    }

    this.$router.push(path)
  }
}
